import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { SEO, Layout, Container, PostOptions } from "../components/index"

// Declaring query here allows us to shadow components
export const query = graphql`
  query ($slug: String!, $category: String!) {
    sanityCategories(slug: { current: { eq: $slug } }) {
      name
      description
      slug {
        current
      }
    }

    sanityPages(templateKey: { eq: "blog" }) {
      slug {
        current
      }
    }

    categories: allSanityCategories {
      ...categories
    }

    authors: allSanityAuthors {
      ...authors
    }

    posts: allSanityPosts(
      filter: { category: { elemMatch: { name: { eq: $category } } } }
    ) {
      ...posts
    }
  }
`

const CategoryTemplate = ({ data }) => {
  let category = data.sanityCategories
  let items = data.posts.edges
  let parent = data.sanityPages.slug.current
  let categories = data.categories.nodes
  let authors = data.authors.nodes

  for (var item in items) {
    if (items[item].node) {
      items[item] = {
        title: items[item].node.title,
        description: `${items[item].node.excerpt}..`,
        slug: { current: `/${parent}/${items[item].node.slug.current}` },
        author: items[item].node.author,
        publishedAt: items[item].node.publishedAt,
        mainImage: items[item].node.mainImage,
        itemImage: { _type: "image" },
        category: items[item].node.category,
      }
      delete items[item].node
    }
  }

  items = { items, numColumns: "one" }

  return (
    <Layout>
      {category.name && (
        <h1>
          <Link to={`/${parent}`} title={parent}>
            Articles—
          </Link>
          {category.name}
        </h1>
      )}
      {category.description && <p>{category.description}</p>}
      <PostOptions
        authors={authors}
        category={category}
        categories={categories}
        posts={items}
      />
    </Layout>
  )
}

export default CategoryTemplate

export function Head({ data }) {
  let { name, description } = data.sanityCategories

  return <SEO title={name} description={description} />
}
